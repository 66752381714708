import React from "react"
import BasicPage from "../components/PageWrapper/BasicPage"

const Datenschutz = (props: any) => {
  return (
    <>
      <BasicPage
        title="Datenschutzerklärung"
        location={props.location}
        noindex={true}
      >
        <section className="container bg-white py-8 md:px-6 mx-auto max-w-3xl">
          <h2>
            1) Information &uuml;ber die Erhebung personenbezogener Daten und
            Kontaktdaten des Verantwortlichen
          </h2>
          <p>
            <strong>1.1</strong> Wir freuen uns, dass Sie unsere Website
            besuchen und bedanken uns f&uuml;r Ihr Interesse. Im Folgenden
            informieren wir Sie &uuml;ber den Umgang mit Ihren personenbezogenen
            Daten bei der Nutzung unserer Website. Personenbezogene Daten sind
            hierbei alle Daten, mit denen Sie pers&ouml;nlich identifiziert
            werden k&ouml;nnen.
          </p>
          <p>
            <strong>1.2</strong> Verantwortlicher f&uuml;r die Datenverarbeitung
            auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO)
            ist Lenz Zuber, smartificate GmbH, Schulstraße 28, 22113
            Oststeinbek, Deutschland, E-Mail: support@smartificate.de. Der
            f&uuml;r die Verarbeitung von personenbezogenen Daten
            Verantwortliche ist diejenige nat&uuml;rliche oder juristische
            Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke
            und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
          </p>
          <p>
            <strong>1.3</strong> Diese Website nutzt aus Sicherheitsgr&uuml;nden
            und zum Schutz der &Uuml;bertragung personenbezogener Daten und
            anderer vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an
            den Verantwortlichen) eine SSL-bzw. TLS-Verschl&uuml;sselung. Sie
            k&ouml;nnen eine verschl&uuml;sselte Verbindung an der Zeichenfolge
            &bdquo;https://&ldquo; und dem Schloss-Symbol in Ihrer Browserzeile
            erkennen.
          </p>
          <h2>
            2) Datenerfassung beim Besuch unserer Website und Weitergabe von
            Daten
          </h2>
          <p>
            Bei der blo&szlig; informatorischen Nutzung unserer Website, also
            wenn Sie sich nicht registrieren oder uns anderweitig Informationen
            &uuml;bermitteln, erheben wir nur solche Daten, die Ihr Browser an
            unseren Server &uuml;bermittelt (sog.
            &bdquo;Server-Logfiles&ldquo;). Wenn Sie unsere Website aufrufen,
            erheben wir die folgenden Daten, die f&uuml;r uns technisch
            erforderlich sind, um Ihnen die Website anzuzeigen:
          </p>
          <ul>
            <li>Unsere besuchte Website</li>
            <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
            <li>Menge der gesendeten Daten in Byte</li>
            <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
            <li>Verwendeter Browser</li>
            <li>Verwendetes Betriebssystem</li>
            <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
          </ul>
          <p>
            Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO
            auf Basis unseres berechtigten Interesses an der Verbesserung der
            Stabilit&auml;t und Funktionalit&auml;t unserer Website. Eine
            Weitergabe oder anderweitige Verwendung der Daten findet nicht
            statt. Wir behalten uns allerdings vor, die Server-Logfiles
            nachtr&auml;glich zu &uuml;berpr&uuml;fen, sollten konkrete
            Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.
          </p>
          <p>
            Die Weitergabe Ihrer Daten erfolgt nur auf Grundlage des Art. 6 Abs.
            1 lit. b DSGVO und damit zum Zwecke der Umsetzung vertraglicher
            Abreden.
          </p>
          <h2>3) Hosting &amp; Content-Delivery-Network</h2>
          <p>Cloudflare</p>
          <p>
            Auf unserer Website verwenden wir ein sog. Content Delivery Network
            ("CDN") des Technologiedienstleisters Cloudflare Inc., 101 Townsend
            St. San Francisco, CA 94107, USA ("Cloudflare"). Bei einem Content
            Delivery Network handelt es sich um einen Online-Dienst, mit dessen
            Hilfe insbesondere gro&szlig;e Mediendateien (wie z.B. Grafiken,
            Seiteninhalte oder Skripte) durch ein Netz regional verteilter und
            &uuml;ber das Internet verbundener Server ausgeliefert werden. Der
            Einsatz des Content Delivery Network von Cloudflare hilft uns bei
            der Optimierung der Ladegeschwindigkeiten unserer Website.
          </p>
          <p>
            Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO
            auf Basis unseres berechtigten Interesses an einer sicheren und
            effizienten Bereitstellung, sowie Verbesserung der Stabilit&auml;t
            und Funktionalit&auml;t unserer Website.
          </p>
          <p>
            Wir haben mit Cloudfare einen Auftragsverarbeitungsvertrag (Data
            Processing Addendum, einsehbar unter{" "}
            <a
              href="https://www.cloudflare.com/media/pdf/cloudflare-customer-dpa.pdf"
              target="_blank"
              rel="noopener"
            >
              https://www.cloudflare.com/media/pdf/cloudflare-customer-dpa.pdf
            </a>
            ) abgeschlossen, mit dem Cloudfare verpflichtet wird, die Daten
            unserer Seitenbesucher zu sch&uuml;tzen und sie nicht an Dritte
            weiter zu geben. F&uuml;r die &Uuml;bermittlung von Daten aus der EU
            in die USA beruft sich Cloudfare hierbei auf sog.
            Standarddatenschutzklauseln der Europ&auml;ischen Kommission, welche
            die Einhaltung des europ&auml;ischen Datenschutzniveaus in den USA
            gew&auml;hrleisten sollen.
          </p>
          <p>
            Weitere Informationen finden Sie in der Datenschutzerkl&auml;rung
            von Cloudflare unter:{" "}
            <a
              href="https://www.cloudflare.com/privacypolicy/"
              target="_blank"
              rel="noopener"
            >
              https://www.cloudflare.com/privacypolicy/
            </a>
          </p>
          <h2>4) Cookies</h2>
          <p>
            Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
            bestimmter Funktionen zu erm&ouml;glichen, verwenden wir auf
            verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich um
            kleine Textdateien, die auf Ihrem Endger&auml;t abgelegt werden.
            Einige der von uns verwendeten Cookies werden nach dem Ende der
            Browser-Sitzung, also nach Schlie&szlig;en Ihres Browsers, wieder
            gel&ouml;scht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf
            Ihrem Endger&auml;t und erm&ouml;glichen uns oder unseren
            Partnerunternehmen (Cookies von Drittanbietern), Ihren Browser beim
            n&auml;chsten Besuch wiederzuerkennen (persistente Cookies). Werden
            Cookies gesetzt, erheben und verarbeiten diese im individuellen
            Umfang bestimmte Nutzerinformationen wie Browser- und Standortdaten
            sowie IP-Adresswerte. Persistente Cookies werden automatisiert nach
            einer vorgegebenen Dauer gel&ouml;scht, die sich je nach Cookie
            unterscheiden kann.
          </p>
          <p>
            Sofern durch einzelne von uns implementierte Cookies auch
            personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung
            gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO entweder zur
            Durchf&uuml;hrung des Vertrages oder gem&auml;&szlig; Art. 6 Abs. 1
            lit. f DSGVO zur Wahrung unserer berechtigten Interessen an der
            bestm&ouml;glichen Funktionalit&auml;t der Website sowie einer
            kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.
          </p>
          <p>
            Wir arbeiten unter Umst&auml;nden mit Werbepartnern zusammen, die
            uns helfen, unser Internetangebot f&uuml;r Sie interessanter zu
            gestalten. Zu diesem Zweck werden f&uuml;r diesen Fall bei Ihrem
            Besuch unserer Website auch Cookies von Partnerunternehmen auf Ihrer
            Festplatte gespeichert (Cookies von Drittanbietern). Wenn wir mit
            vorbenannten Werbepartnern zusammenarbeiten, werden Sie &uuml;ber
            den Einsatz derartiger Cookies und den Umfang der jeweils erhobenen
            Informationen innerhalb der nachstehenden Abs&auml;tze individuell
            und gesondert informiert.
          </p>
          <p>
            Bitte beachten Sie, dass Sie Ihren Browser so einstellen
            k&ouml;nnen, dass Sie &uuml;ber das Setzen von Cookies informiert
            werden und einzeln &uuml;ber deren Annahme entscheiden oder die
            Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell
            ausschlie&szlig;en k&ouml;nnen. Jeder Browser unterscheidet sich in
            der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist in dem
            Hilfemen&uuml; jedes Browsers beschrieben, welches Ihnen
            erl&auml;utert, wie Sie Ihre Cookie-Einstellungen &auml;ndern
            k&ouml;nnen. Diese finden Sie f&uuml;r die jeweiligen Browser unter
            den folgenden Links:
          </p>
          <p>
            Internet Explorer:{" "}
            <a
              href="http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies"
              target="_blank"
              rel="noopener"
            >
              http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies
            </a>
          </p>
          <p>
            Firefox:{" "}
            <a
              href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen"
              target="_blank"
              rel="noopener"
            >
              https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
            </a>
          </p>
          <p>
            Chrome:{" "}
            <a
              href="http://support.google.com/chrome/bin/answer.py?hl=de&amp;hlrm=en&amp;answer=95647"
              target="_blank"
              rel="noopener"
            >
              http://support.google.com/chrome/bin/answer.py?hl=de&amp;hlrm=en&amp;answer=95647
            </a>
          </p>
          <p>
            Safari:{" "}
            <a
              href="https://support.apple.com/de-de/guide/safari/sfri11471/mac"
              target="_blank"
              rel="noopener"
            >
              https://support.apple.com/de-de/guide/safari/sfri11471/mac
            </a>
          </p>
          <p>
            Opera:{" "}
            <a
              href="http://help.opera.com/Windows/10.20/de/cookies.html"
              target="_blank"
              rel="noopener"
            >
              http://help.opera.com/Windows/10.20/de/cookies.html
            </a>
          </p>
          <p>
            Bitte beachten Sie, dass bei Nichtannahme von Cookies die
            Funktionalit&auml;t unserer Website eingeschr&auml;nkt sein kann.
          </p>
          <h2>5) Kontaktaufnahme</h2>
          <p>
            <strong>5.1</strong> HubSpot
          </p>
          <p>
            Auf dieser Website werden mit Technologien der HubSpot Ireland Ltd.,
            2nd Floor 30 North Wall Quay, Dublin 1, Irland.
            (&bdquo;HubSpot&ldquo;), anonymisierte Daten zum Zweck der
            Webanalyse und zum Betreiben des Live-Chat-Systems, das der
            Beantwortung von Live-Support-Anfragen dient, gesammelt und
            gespeichert. Aus diesen anonymisierten Daten k&ouml;nnen unter einem
            Pseudonym Nutzungsprofile erstellt werden. Hierzu k&ouml;nnen
            Cookies eingesetzt werden. Bei Cookies handelt es sich um kleine
            Textdateien, die lokal im Zwischenspeicher des Internet-Browsers des
            Seitenbesuchers gespeichert werden. Die Cookies erm&ouml;glichen die
            Wiedererkennung des Internet-Browsers. Sofern die so erhobenen
            Informationen einen Personenbezug aufweisen, erfolgt die
            Verarbeitung gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis
            unseres berechtigten Interesses an effektiver Kundenbetreuung und
            der statistischen Analyse des Nutzerverhaltens zu
            Optimierungszwecken.
          </p>
          <p>
            Die mit den HubSpot-Technologien erhobenen Daten werden ohne die
            gesondert erteilte Zustimmung des Betroffenen nicht dazu benutzt,
            den Besucher dieser Website pers&ouml;nlich zu identifizieren und
            nicht mit personenbezogenen Daten &uuml;ber den Tr&auml;ger des
            Pseudonyms zusammengef&uuml;hrt. Um die Speicherung von
            HubSpot-Cookies zu vermeiden, k&ouml;nnen Sie Ihren Internetbrowser
            so einstellen, dass zuk&uuml;nftig keine Cookies mehr auf Ihrem
            Computer abgelegt werden k&ouml;nnen bzw. bereits abgelegte Cookies
            gel&ouml;scht werden. Das Abschalten s&auml;mtlicher Cookies kann
            jedoch dazu f&uuml;hren, dass einige Funktionen auf unseren
            Internetseiten nicht mehr ausgef&uuml;hrt werden k&ouml;nnen. Der
            Datenerhebung und &ndash;speicherung zum Zwecke der Erstellung eines
            pseudonymisierten Nutzungsprofils k&ouml;nnen Sie jederzeit mit
            Wirkung f&uuml;r die Zukunft widersprechen, indem Sie uns Ihren
            Widerspruch formlos per E-Mail an die im Impressum genannte
            E-Mail-Adresse schicken.
          </p>
          <p>
            Wir haben mit HubSpot einen Auftragsverarbeitungsvertrag
            abgeschlossen, mit dem wir den Anbieter dazu verpflichten, die Daten
            unserer Kunden zu sch&uuml;tzen und sie nicht an Dritte
            weiterzugeben.
          </p>
          <p>
            Die Datenschutzbestimmungen von HubSpot k&ouml;nnen Sie hier
            einsehen:{" "}
            <a
              href="https://legal.hubspot.com/privacy-policy"
              target="_blank"
              rel="noopener"
            >
              https://legal.hubspot.com/privacy-policy
            </a>
          </p>
          <p>
            <strong>5.2</strong> Im Rahmen der Kontaktaufnahme mit uns (z.B. per
            Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben.
            Welche Daten im Falle der Nutzung eines Kontaktformulars erhoben
            werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese
            Daten werden ausschlie&szlig;lich zum Zweck der Beantwortung Ihres
            Anliegens bzw. f&uuml;r die Kontaktaufnahme und die damit verbundene
            technische Administration gespeichert und verwendet. Rechtsgrundlage
            f&uuml;r die Verarbeitung dieser Daten ist unser berechtigtes
            Interesse an der Beantwortung Ihres Anliegens gem&auml;&szlig; Art.
            6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss
            eines Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r
            die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach
            abschlie&szlig;ender Bearbeitung Ihrer Anfrage gel&ouml;scht. Dies
            ist der Fall, wenn sich aus den Umst&auml;nden entnehmen l&auml;sst,
            dass der betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist
            und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
          </p>
          <p>
            <strong>5.3</strong> Hubspot
          </p>
          <p>
            Wir nutzen zur Bearbeitung von Kundenanfragen das
            E-Mail-Ticketsystem der HubSpot Ireland Ltd., 2nd Floor 30 North
            Wall Quay, Dublin 1, Irland. (&bdquo;Hubspot&ldquo;). Stellen Nutzer
            unserer Webseite Kontaktanfragen per Mail, werden diese im
            Ticketsystem gespeichert und organisiert, um eine chronologische
            Bearbeitung zu erm&ouml;glichen und das Serviceerlebnis zu
            verbessern. Nutzer k&ouml;nnen &uuml;ber die individuell vergebene
            Ticket-Nummer stets den aktuellen Stand der Bearbeitung ihres
            Anliegens einsehen.
          </p>
          <p>
            Ausschlie&szlig;lich f&uuml;r die Organisation der Anfragen und
            deren Bearbeitung werden personenbezogene Daten soweit, wie in der
            Anfrage bereitgestellt, jedenfalls aber Name, Vorname und
            E-Mail-Adresse erhoben, an Hubspot &uuml;bermittelt, dort
            gespeichert und ausgelesen.
          </p>
          <p>
            Rechtsgrundlage f&uuml;r die Verarbeitung dieser Daten ist unser
            berechtigtes Interesse an der effizienten Ausgestaltung unseres
            Kundenservice, an der schnellstm&ouml;glichen Beantwortung Ihres
            Anliegens und der Optimierung unseres Service-Angebots
            gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO.
          </p>
          <p>
            Wir haben mit Hubspot einen Auftragsverarbeitungsvertrag
            abgeschlossen, mit dem wir Hubspot verpflichten, die Daten unserer
            Kunden zu sch&uuml;tzen und sie nicht an Dritte weiterzugeben.
          </p>
          <p>
            Ihre Daten werden nach abschlie&szlig;ender Bearbeitung Ihrer
            Anfrage gel&ouml;scht. Dies ist der Fall, wenn sich aus den
            Umst&auml;nden entnehmen l&auml;sst, dass der betroffene Sachverhalt
            abschlie&szlig;end gekl&auml;rt ist und sofern keine gesetzlichen
            Aufbewahrungspflichten entgegenstehen.
          </p>
          <p>
            Weitere Informationen zum Datenschutz von Hubspot erhalten Sie unter{" "}
            <a
              href="https://legal.hubspot.com/privacy-policy"
              target="_blank"
              rel="noopener"
            >
              https://legal.hubspot.com/privacy-policy
            </a>
          </p>
          <h2>6) Nutzung von Kundendaten zur Direktwerbung</h2>
          <p>- Newsletterversand via Sendinblue</p>
          <p>
            Der Versand unserer E-Mail-Newsletter erfolgt &uuml;ber den
            technischen Dienstleister Sendinblue GmbH, K&ouml;penicker Str. 126,
            10179 Berlin, an den wir Ihre bei der Newsletteranmeldung
            bereitgestellten Daten weitergeben. Diese Weitergabe erfolgt
            gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO und dient unserem
            berechtigten Interesse an der Verwendung eines werbewirksamen,
            sicheren und nutzerfreundlichen Newslettersystems. Die von Ihnen
            zwecks Newsletterbezugs eingegebenen Daten (z.B. E-Mail-Adresse)
            werden auf den Servern von Sendinblue in der EU gespeichert.
          </p>
          <p>
            Sendinblue verwendet diese Informationen zum Versand und zur
            statistischen Auswertung der Newsletter in unserem Auftrag. F&uuml;r
            die Auswertung beinhalten die versendeten E-Mails sog. Web-Beacons
            bzw. Trackings-Pixel, die Ein-Pixel-Bilddateien darstellen, die auf
            unserer Website gespeichert sind. So kann festgestellt werden, ob
            eine Newsletter-Nachricht ge&ouml;ffnet und welche Links ggf.
            angeklickt wurden. Au&szlig;erdem werden technische Informationen
            erfasst (z.B. Zeitpunkt des Abrufs, IP-Adresse, Browsertyp und
            Betriebssystem). Die Daten werden ausschlie&szlig;lich
            pseudonymisiert erhoben und werden nicht mit Ihren weiteren
            pers&ouml;nlichen Daten verkn&uuml;pft, eine direkte
            Personenbeziehbarkeit wird ausgeschlossen. Diese Daten dienen
            ausschlie&szlig;lich der statistischen Analyse von
            Newsletterkampagnen. Die Ergebnisse dieser Analysen k&ouml;nnen
            genutzt werden, um k&uuml;nftige Newsletter besser an die Interessen
            der Empf&auml;nger anzupassen. Wenn Sie der Datenanalyse zu
            statistischen Auswertungszwecken widersprechen m&ouml;chten,
            m&uuml;ssen Sie den Newsletterbezug abbestellen.
          </p>
          <p>
            Des Weiteren kann Sendinblue diese Daten gem&auml;&szlig; Art. 6
            Abs. 1 lit. f DSGVO selbst aufgrund seines eigenen berechtigten
            Interesses an der bedarfsgerechten Ausgestaltung und der Optimierung
            des Dienstes sowie zu Marktforschungszwecken nutzen, um etwa zu
            bestimmen, aus welchen L&auml;ndern die Empf&auml;nger kommen.
            Sendinblue nutzt die Daten unserer Newsletterempf&auml;nger jedoch
            nicht, um diese selbst anzuschreiben oder sie an Dritte
            weiterzugeben.
          </p>
          <p>
            Wir haben mit Sendinblue einen Auftragsverarbeitungsvertrag
            abgeschlossen, mit dem wir Sendinblue verpflichten, die Daten
            unserer Kunden zu sch&uuml;tzen und sie nicht an Dritte
            weiterzugeben.
          </p>
          <p>
            Die Datenschutzbestimmungen von Sendinblue k&ouml;nnen Sie hier
            einsehen:{" "}
            <a
              href="https://de.sendinblue.com/legal/privacypolicy/"
              target="_blank"
              rel="noopener"
            >
              https://de.sendinblue.com/legal/privacypolicy/
            </a>
          </p>
          <h2>7) Online-Marketing</h2>
          <p>
            <strong>7.1</strong> Facebook Pixel f&uuml;r die Erstellung von
            Custom Audiences (mit Cookie Consent Tool)
          </p>
          <p>
            Innerhalb unseres Onlineangebotes wird das sog. "Facebook-Pixel" des
            sozialen Netzwerkes Facebook eingesetzt, welches von der Meta
            Platforms Ireland Limited, 4 Grand Canal Quare, Dublin 2, Irland
            ("Facebook&ldquo;) betrieben wird.
          </p>
          <p>
            Klickt ein Nutzer auf eine von uns geschaltete Werbeanzeige, die bei
            Facebook ausgespielt wird, wird der URL unserer verkn&uuml;pften
            Seite durch Facebook Pixel ein Zusatz angef&uuml;gt. Sofern unsere
            Seite &uuml;ber Pixel das Teilen von Daten mit Facebook erlaubt,
            wird dieser URL-Parameter in den Browser des Nutzers per Cookie
            eingeschrieben, welches unsere verkn&uuml;pfte Seite selbst setzt.
            Dieses Cookie wird von Facebook Pixel sodann ausgelesen und
            erm&ouml;glicht eine Weiterleitung der Daten an Facebook.
          </p>
          <p>
            Mit Hilfe des Facebook-Pixels ist es Facebook einerseits
            m&ouml;glich, die Besucher unseres Onlineangebotes als Zielgruppe
            f&uuml;r die Darstellung von Anzeigen (sog. "Facebook-Ads") zu
            bestimmen. Dementsprechend setzen wir das Facebook-Pixel ein, um die
            durch uns geschalteten Facebook-Ads nur solchen Facebook-Nutzern
            anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt
            haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten
            Themen oder Produkten, die anhand der besuchten Webseiten bestimmt
            werden) aufweisen, welche wir an Facebook &uuml;bermitteln (sog.
            &bdquo;Custom Audiences&ldquo;). Mit Hilfe des Facebook-Pixels
            m&ouml;chten wir ebenfalls sicherstellen, dass unsere Facebook-Ads
            dem potentiellen Interesse der Nutzer entsprechen und nicht
            bel&auml;stigend wirken. So k&ouml;nnen wir weiter die Wirksamkeit
            der Facebook-Werbeanzeigen f&uuml;r statistische und
            Marktforschungszwecke auswerten, indem wir nachvollziehen, ob Nutzer
            nach dem Klick auf eine Facebook-Werbeanzeige auf unsere Website
            weitergeleitet wurden (sog. &bdquo;Conversion&ldquo;).
          </p>
          <p>
            Die erhobenen Daten sind f&uuml;r uns anonym, bieten uns also keine
            R&uuml;ckschl&uuml;sse auf die Identit&auml;t der Nutzer. Allerdings
            werden die Daten von Facebook gespeichert und verarbeitet, sodass
            eine Verbindung zum jeweiligen Nutzerprofil m&ouml;glich ist und
            Facebook die Daten f&uuml;r eigene Werbezwecke, entsprechend der
            Facebook- Datenverwendungsrichtlinie (
            <a
              href="https://www.facebook.com/about/privacy/"
              target="_blank"
              rel="noopener"
            >
              https://www.facebook.com/about/privacy/
            </a>
            ) verwenden kann. Die Daten k&ouml;nnen Facebook sowie dessen
            Partnern das Schalten von Werbeanzeigen auf und au&szlig;erhalb von
            Facebook erm&ouml;glichen.
          </p>
          <p>
            Die mit dem Einsatz des Facebook Pixels einhergehenden
            Datenverarbeitungen erfolgen ausschlie&szlig;lich bei Vorliegen
            Ihrer ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs.
            1 lit. a DSGVO. Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit
            mit Wirkung f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf
            auszu&uuml;ben, entfernen Sie das im auf der Website eingebundenen
            &bdquo;Cookie-Consent-Tool&ldquo; gesetzte H&auml;kchen neben der
            Einstellung f&uuml;r den &bdquo;Facebook Pixel&ldquo;.
          </p>
          <p>
            <strong>7.2</strong> Verwendung von Affiliate-Programmen
          </p>
          <p>- Eigenes Affiliate-Programm</p>
          <p>
            Im Zusammenhang mit den Produktpr&auml;sentationen unserer Webseite
            unterhalten wir ein eigenes Affiliate-Programm, in dessen Rahmen wir
            interessierten Drittseitenbetreibern Partner-Links zur Platzierung
            auf ihren Webseiten bereitstellen, die auf unsere Angebote
            f&uuml;hren. F&uuml;r das Affiliate-Programm werden Cookies
            verwendet, die grunds&auml;tzlich auf der Partnerseite nach Klick
            auf einen entsprechenden Partner-Link gesetzt werden und f&uuml;r
            die wir insofern nicht datenschutzrechtlich verantwortlich sind. Bei
            Cookies handelt es sich um kleine Textdateien, die auf Ihrem
            Endger&auml;t abgelegt werden, um die Herkunft von Transaktionen
            (z.B. &bdquo;Sales leads&ldquo;) nachvollziehen zu k&ouml;nnen, die
            &uuml;ber solche Links generiert wurden. Dabei k&ouml;nnen wir unter
            anderem erkennen, dass Sie den Partnerlink geklickt haben und auf
            unsere Webseite weitergeleitet wurden. Diese Informationen werden
            zur Zahlungsabwicklung zwischen uns und den Affiliate-Partnern
            ben&ouml;tigt. Sofern die Informationen auch personenbezogen Daten
            enthalten, erfolgt die beschriebene Verarbeitung auf Grundlage
            unseres berechtigten finanziellen Interesses an der Abwicklung von
            Provisionszahlungen gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO.
          </p>
          <p>
            Wenn Sie die Auswertung des Nutzerverhaltens via Cookies blockieren
            m&ouml;chten, k&ouml;nnen Sie Ihren Browser so einstellen, dass Sie
            &uuml;ber das Setzen von Cookies informiert werden und einzeln
            &uuml;ber deren Annahme entscheiden oder die Annahme von Cookies
            f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en.
          </p>
          <h2>8) Webanalysedienste</h2>
          <p>
            <strong>8.1</strong> Google (Universal) Analytics mit Google Signals
          </p>
          <p>
            Diese Website benutzt Google (Universal) Analytics, einen
            Webanalysedienst der Google Ireland Limited, Gordon House, 4 Barrow
            St, Dublin, D04 E5W5, Irland ("Google"). Google (Universal)
            Analytics verwendet sog. "Cookies", das sind Textdateien, die auf
            Ihrem Endger&auml;t gespeichert werden und die eine Analyse Ihrer
            Nutzung der Website erm&ouml;glichen. Die durch das Cookie erzeugten
            Informationen &uuml;ber Ihre Benutzung dieser Website
            (einschlie&szlig;lich der gek&uuml;rzten IP-Adresse) werden in der
            Regel an einen Server von Google &uuml;bertragen und dort
            gespeichert, hierbei kann es auch zu einer &Uuml;bermittlung an die
            Server der Google LLC. in den USA kommen.
          </p>
          <p>
            Diese Website verwendet Google (Universal) Analytics
            ausschlie&szlig;lich mit der Erweiterung "_anonymizeIp()", die eine
            Anonymisierung der IP-Adresse durch K&uuml;rzung sicherstellt und
            eine direkte Personenbeziehbarkeit ausschlie&szlig;t. Durch die
            Erweiterung wird Ihre IP-Adresse von Google innerhalb von
            Mitgliedstaaten der Europ&auml;ischen Union oder in anderen
            Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen
            Wirtschaftsraum zuvor gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird
            die volle IP-Adresse an einen Server von Google LLC.in den USA
            &uuml;bertragen und dort gek&uuml;rzt.
          </p>
          <p>
            In unserem Auftrag wird Google diese Informationen benutzen, um Ihre
            Nutzung der Website auszuwerten, um Reports &uuml;ber die
            Websiteaktivit&auml;ten zusammenzustellen und um weitere mit der
            Websitenutzung und der Internetnutzung verbundene Dienstleistungen
            uns gegen&uuml;ber zu erbringen. Dabei wird die im Rahmen von Google
            (Universal) Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse
            nicht mit anderen Daten von Google zusammengef&uuml;hrt.
          </p>
          <p>
            Google Analytics erm&ouml;glicht &uuml;ber eine spezielle Funktion,
            die sog. &bdquo;demografischen Merkmale&ldquo;, dar&uuml;ber hinaus
            die Erstellung von Statistiken mit Aussagen &uuml;ber Alter,
            Geschlecht und Interessen der Seitenbesucher auf Basis einer
            Auswertung von interessenbezogener Werbung und unter Hinzuziehung
            von Drittanbieterinformationen. Dies erlaubt die Definition und
            Differenzierung von Nutzerkreisen der Webseite zum Zwecke der
            zielgruppenoptimierten Ausrichtung von Marketingma&szlig;nahmen.
            &Uuml;ber die &bdquo;demografischen Merkmale&ldquo; erfasste
            Datens&auml;tze k&ouml;nnen jedoch keiner bestimmten Person
            zugeordnet werden.
          </p>
          <p>
            Alle oben beschriebenen Verarbeitungen, insbesondere das Setzen von
            Google Analytics-Cookies f&uuml;r das Auslesen von Informationen auf
            dem verwendeten Endger&auml;t, werden nur dann vollzogen, wenn Sie
            uns gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO dazu Ihre
            ausdr&uuml;ckliche Einwilligung erteilt haben. Ohne diese
            Einwilligungserteilung unterbleibt der Einsatz von Google Analytics
            w&auml;hrend Ihres Seitenbesuchs.
          </p>
          <p>
            Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung
            f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben,
            deaktivieren Sie diesen Dienst bitte in dem auf der Webseite
            bereitgestellten &bdquo;Cookie-Consent-Tool&ldquo;. Wir haben mit
            Google f&uuml;r die Nutzung von Google Analytics einen
            Auftragsverarbeitungsvertrag abgeschlossen, mit dem Google
            verpflichtet wird, die Daten unserer Seitenbesucher zu sch&uuml;tzen
            und sie nicht an Dritte weiter zu geben. F&uuml;r die
            &Uuml;bermittlung von Daten aus der EU in die USA beruft sich Google
            hierbei auf sog. Standarddatenschutzklauseln der Europ&auml;ischen
            Kommission, welche die Einhaltung des europ&auml;ischen
            Datenschutzniveaus in den USA gew&auml;hrleisten sollen.
          </p>
          <p>
            Weitere Hinweise zu Google (Universal) Analytics finden Sie hier:{" "}
            <a
              href="https://policies.google.com/privacy?hl=de&amp;gl=de"
              target="_blank"
              rel="noopener"
            >
              https://policies.google.com/privacy?hl=de&amp;gl=de
            </a>
          </p>
          <p>
            Diese Website verwendet als Erweiterung von Google Analytics auch
            den Service Google Signals. Mit Google Signals k&ouml;nnen wir
            ger&auml;te&uuml;bergreifende Berichte durch Google erstellen lassen
            (sog. &bdquo;Cross Device Tracking&ldquo;). Sofern Sie in Ihren
            Einstellungen in Ihrem Google Konto die &bdquo;personalisierten
            Anzeigen&ldquo; aktiviert haben und Sie Ihre internetf&auml;higen
            Ger&auml;te mit Ihrem Google Konto verkn&uuml;pft haben, kann Google
            das Nutzerverhalten bei entsprechender Einwilligungserteilung in den
            Einsatz von Google Analytics gem&auml;&szlig; Art. 6 Abs. 1 lit. a
            DSGVO (s.o.) ger&auml;te&uuml;bergreifend analysieren und hierauf
            basierende Datenbankmodelle erstellen. Ber&uuml;cksichtigt werden
            dabei die Anmeldungen und Ger&auml;tetypen aller Seitenbesucher, die
            in einem Google-Konto angemeldet waren und eine Conversion
            ausgef&uuml;hrt haben. Die Daten zeigen unter anderem, auf welchem
            Ger&auml;t Sie das erste Mal auf eine Anzeige geklickt und auf
            welchem Ger&auml;t die zugeh&ouml;rige Conversion stattgefunden hat.
            Wir erhalten hierbei keine personenbezogenen Daten von Google,
            sondern lediglich auf Basis von Google Signals erstellte
            Statistiken. Sie haben die M&ouml;glichkeit, die Funktion
            &bdquo;personalisierte Anzeigen&ldquo; in den Einstellungen Ihres
            Google-Kontos zu deaktivieren und damit die
            ger&auml;te&uuml;bergreifende Analyse abzustellen. Folgen Sie hierzu
            den Hinweisen auf dieser Seite:{" "}
            <a
              href="https://support.google.com/ads/answer/2662922?hl=de"
              target="_blank"
              rel="noopener"
            >
              https://support.google.com/ads/answer/2662922?hl=de
            </a>
          </p>
          <p>
            Weitergehende Informationen zu Google Signals k&ouml;nnen Sie hier
            nachlesen:{" "}
            <a
              href="https://support.google.com/analytics/answer/7532985?hl=de"
              target="_blank"
              rel="noopener"
            >
              https://support.google.com/analytics/answer/7532985?hl=de
            </a>
          </p>
          <p>
            <strong>8.2</strong> - Hotjar (hotjar Ltd.)
          </p>
          <p>
            Diese Website nutzt den Webanalysedienst Hotjar der Hotjar Ltd.
            Hotjar Ltd. ist ein europ&auml;isches Unternehmen mit Sitz in Malta
            (Hotjar Ltd, Level 2, St Julians Business Centre, 3, Elia Zammit
            Street, St Julians STJ 1000, Malta, Europe Tel.: +1 (855) 464-6788).
          </p>
          <p>
            Mit diesem Tool lassen sich Bewegungen auf den Webseiten, auf denen
            Hotjar eingesetzt wird, nachvollziehen (sog. Heatmaps). So ist
            beispielsweise erkennbar, wie weit Nutzer scrollen und welche
            Schaltfl&auml;chen die Nutzer wie oft anklicken. Weiterhin ist es
            mithilfe des Tools auch m&ouml;glich, ein Feedback direkt von den
            Nutzern der Website einzuholen. Auf diese Weise erlangen wir
            wertvolle Informationen, um unsere Websites noch schneller und
            kundenfreundlicher zu gestalten. Wir achten beim Einsatz dieses
            Tools besonders auf den Schutz Ihrer personenbezogenen Daten. So
            k&ouml;nnen wir nur nachvollziehen, welche Schaltfl&auml;chen Sie
            anklicken und wie weit sie scrollen. Bereiche der Websiten, in denen
            personenbezogene Daten von Ihnen oder Dritten angezeigt werden,
            werden von Hotjar automatisch ausgeblendet, und sind somit zu keinem
            Zeitpunkt nachvollziehbar.
          </p>
          <p>
            Alle oben beschriebenen Verarbeitungen, insbesondere das Auslesen
            von Informationen auf dem verwendeten Endger&auml;t, werden nur dann
            vollzogen, wenn Sie uns gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO
            dazu Ihre ausdr&uuml;ckliche Einwilligung erteilt haben. Ohne diese
            Einwilligungserteilung unterbleibt der Einsatz von Hotjar
            w&auml;hrend Ihres Seitenbesuchs.
          </p>
          <p>
            Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung
            f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben,
            deaktivieren Sie diesen Dienst bitte in dem auf der Webseite
            bereitgestellten &bdquo;Cookie-Consent-Tool&ldquo;.
          </p>
          <p>
            Hotjar bietet jedem Nutzer alternativ die M&ouml;glichkeit, mithilfe
            eines &ldquo;Do Not Track-Headers&rdquo; den Einsatz des Tools
            Hotjar zu unterbinden, sodass keine Daten &uuml;ber den Besuch der
            jeweiligen Website aufgezeichnet werden. Es handelt sich hierbei um
            eine Einstellung, die alle &uuml;blichen Browser in der jeweilig
            aktuellen Version unterst&uuml;tzt. Hierzu sendet Ihr Browser eine
            Anfrage an Hotjar, mit dem Hinweis das Tracking des jeweiligen
            Nutzers zu deaktivieren. Sollten Sie unsere Website mit
            unterschiedlichen Browsern/Rechnern nutzen, m&uuml;ssen Sie den
            &ldquo;Do Not Track-Header&rdquo; f&uuml;r jeden dieser
            Browser/Rechner separat einrichten.
          </p>
          <p>
            Eine detailierte Anleitungen mit Informationen zu Ihrem Browser
            finden Sie unter:{" "}
            <a
              href="https://www.hotjar.com/de/legal/policies/do-not-track/"
              target="_blank"
              rel="noopener"
            >
              https://www.hotjar.com/de/legal/policies/do-not-track/
            </a>
          </p>
          <p>
            Weitere Informationen &uuml;ber Hotjar Ltd. und &uuml;ber das Tool
            Hotjar finden Sie unter:{" "}
            <a href="https://www.hotjar.com/" target="_blank" rel="noopener">
              https://www.hotjar.com
            </a>
          </p>
          <p>
            Die Datenschutzerkl&auml;rung der Hotjar Ltd. finden Sie unter:{" "}
            <a
              href="https://www.hotjar.com/privacy"
              target="_blank"
              rel="noopener"
            >
              https://www.hotjar.com/privacy
            </a>
          </p>
          <h2>9) Retargeting/ Remarketing/ Empfehlungswerbung</h2>
          <p>Google Ads Remarketing</p>
          <p>
            Unsere Website nutzt die Funktionen von Google Ads Remarketing,
            hiermit werben wir f&uuml;r diese Website in den
            Google-Suchergebnissen, sowie auf Dritt-Websites. Anbieter ist die
            Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5,
            Irland (&ldquo;Google&rdquo;). Zu diesem Zweck setzt Google ein
            Cookie im Browser Ihres Endger&auml;ts, welches automatisch mittels
            einer pseudonymen Cookie-ID und auf Grundlage der von Ihnen
            besuchten Seiten eine interessensbasierte Werbung erm&ouml;glicht.
            Eine dar&uuml;berhinausgehende Datenverarbeitung findet nur statt,
            sofern Sie gegen&uuml;ber Google zugestimmt haben, dass Ihr
            Internet-- und App-Browserverlauf von Google mit ihrem Google-Konto
            verkn&uuml;pft wird und Informationen aus ihrem Google-Konto zum
            Personalisieren von Anzeigen verwendet werden, die sie im Web
            betrachten. Sind sie in diesem Fall w&auml;hrend des Seitenbesuchs
            unserer Webseite bei Google eingeloggt, verwendet Google Ihre Daten
            zusammen mit Google Analytics-Daten, um Zielgruppenlisten f&uuml;r
            ger&auml;te&uuml;bergreifendes Remarketing zu erstellen und zu
            definieren. Dazu werden Ihre personenbezogenen Daten von Google
            vor&uuml;bergehend mit Google Analytics-Daten verkn&uuml;pft, um
            Zielgruppen zu bilden. Im Rahmen der Nutzung von Google Ads
            Remarketing kann es auch zu einer &Uuml;bermittlung von
            personenbezogenen Daten an die Server der Google LLC. in den USA
            kommen.
          </p>
          <p>
            Alle oben beschriebenen Verarbeitungen, insbesondere das Setzen von
            Cookies f&uuml;r das Auslesen von Informationen auf dem verwendeten
            Endger&auml;t, werden nur dann vollzogen, wenn Sie uns
            gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO dazu Ihre
            ausdr&uuml;ckliche Einwilligung erteilt haben. Ohne diese
            Einwilligungserteilung unterbleibt der Einsatz von Google Ads
            Remarketingl w&auml;hrend Ihres Seitenbesuchs.
          </p>
          <p>
            Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung
            f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben,
            deaktivieren Sie diesen Dienst bitte in dem auf der Webseite
            bereitgestellten &bdquo;Cookie-Consent-Tool&ldquo;.
          </p>
          <p>
            Sie k&ouml;nnen dem Setzen von Cookies durch Google Ads Remarketing
            dar&uuml;ber hinaus auch dauerhaft widersprechen, indem Sie das
            unter folgendem Link verf&uuml;gbare Browser-Plug-in von Google
            herunterladen und installieren:{" "}
            <a
              href="https://www.google.com/settings/ads/onweb/"
              target="_blank"
              rel="noopener"
            >
              https://www.google.com/settings/ads/onweb/
            </a>
          </p>
          <p>
            Weitergehende Informationen und die Datenschutzbestimmungen
            bez&uuml;glich Werbung und Google k&ouml;nnen Sie hier einsehen:
          </p>
          <p>
            <a
              href="https://www.google.com/policies/technologies/ads/"
              target="_blank"
              rel="noopener"
            >
              https://www.google.com/policies/technologies/ads/
            </a>
          </p>
          <p>Outbrain Pixel</p>
          <p>
            Innerhalb unseres Onlineangebotes verwenden wir das &bdquo;Outbrain
            Pixel&ldquo; " der Outbrain, Inc., 39 W 13th St, New York 10011, USA
            (&bdquo;Outbrain&ldquo;).Von Outbrain Pixel wird ein Cookie auf
            Ihrem Rechner gesetzt, sofern Sie &uuml;ber eine Werbeanzeige oder
            sonstige Kampagne auf unsere Website gelangt sind. Bei Cookies
            handelt es sich um kleine Textdateien, die auf Ihrem Endger&auml;t
            abgelegt werden. Besucht der Nutzer bestimmte Seiten dieser Website,
            k&ouml;nnen wir erkennen, dass der Nutzer auf die Werbeanzeige
            geklickt hat und zu dieser Seite (Conversion-Seite) weitergeleitet
            wurde. Die mithilfe des Conversion-Cookies erhobenen Informationen
            dienen dazu, Conversion-Statistiken zu erstellen, d.h. zu erfassen,
            wie viele Nutzer nach Anklicken einer Anzeige auf eine
            Conversion-Seite gelangen. Wir erfahren hierdurch die Gesamtanzahl
            der Nutzer, die auf unsere Anzeige geklickt haben und zu einer mit
            einem Conversion-Tracking-Tag versehenen Seite weitergeleitet
            wurden. Wir erhalten jedoch keine Informationen, mit denen sich
            Nutzer pers&ouml;nlich identifizieren lassen.
          </p>
          <p>
            Alle oben beschriebenen Verarbeitungen, insbesondere das Auslesen
            von Informationen auf dem verwendeten Endger&auml;t, werden nur dann
            vollzogen, wenn Sie uns gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO
            dazu Ihre ausdr&uuml;ckliche Einwilligung erteilt haben. Ohne diese
            Einwilligungserteilung unterbleibt der Einsatz dieses Dienstes
            w&auml;hrend Ihres Seitenbesuchs.
          </p>
          <p>
            Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung
            f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben,
            deaktivieren Sie diesen Dienst bitte in dem auf der Webseite
            bereitgestellten &bdquo;Cookie-Consent-Tool&ldquo;.
          </p>
          <p>
            Weite Informationen zu den Datenschutzbestimmungen von Outbrain
            finden sich unter{" "}
            <a
              href="https://www.outbrain.com/de/legal/privacy"
              target="_blank"
              rel="noopener"
            >
              https://www.outbrain.com/de/legal/privacy
            </a>
            .
          </p>
          <p>Taboola</p>
          <p>
            Diese Website verwendet die Retargeting-Technologie der Taboola
            Inc., 16 Madison Square West 7th Floor, New York, NY 10010, USA
            (&bdquo;Taboola&ldquo;). Diese erm&ouml;glicht es, Besucher unserer
            Internetseiten ausgehend vom Nutzungsverhalten gezielt auf weitere
            eigene oder fremde Inhalte in Form von Bannern hinzuweisen, die
            voraussichtlich dem jeweiligen Nutzerinteresse entsprechen. Die
            Einblendung dieser Inhalte erfolgt auf Basis einer Cookie-basierten
            Analyse des fr&uuml;heren Nutzungsverhaltens, wobei aber keine
            personenbezogenen Daten gespeichert werden. F&uuml;r diese
            interessenorientierte Inhaltsbestimmung wird ein Cookie auf Ihrem
            Computer oder mobilen Endger&auml;t gespeichert, um pseudonymisierte
            Daten &uuml;ber Ihr Surfverhalten zu erfassen und so die Inhalte
            individuell auf die gespeicherten Informationen anzupassen.
          </p>
          <p>
            Alle oben beschriebenen Verarbeitungen, insbesondere das Setzen von
            Cookies f&uuml;r das Auslesen von Informationen auf dem verwendeten
            Endger&auml;t, werden nur dann vollzogen, wenn Sie uns
            gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO dazu Ihre
            ausdr&uuml;ckliche Einwilligung erteilt haben. Ohne diese
            Einwilligungserteilung unterbleibt der Einsatz dieses Dienstes
            w&auml;hrend Ihres Seitenbesuchs.
          </p>
          <p>
            Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung
            f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben,
            deaktivieren Sie diesen Dienst bitte in dem auf der Webseite
            bereitgestellten &bdquo;Cookie-Consent-Tool&ldquo;.
          </p>
          <p>TikTok Pixel</p>
          <p>
            Diese Website nutzt den &bdquo;TikTok Pixel&ldquo;, eine
            Tracking-Technologie des sozialen Netzwerkes &bdquo;TikTok&ldquo;
            der TikTok Technology Limited, 10 Earlsfort Terrace, Dublin, D02
            T380, Irland ("TikTok").
          </p>
          <p>
            Mithilfe von Cookies (kleinen Textdateien, die auf dem verwendeten
            Endger&auml;t gespeichert werden) werden Informationen &uuml;ber das
            Surfverhalten auf unserer Website in pseudonymisierter Form
            gesammelt, an TikTok &uuml;bermittelt, dort gespeichert und
            ausgewertet, um sodann die Ausspielung interessenbasierter und
            personalisierter Produktempfehlungen auf TikTok zu erm&ouml;glichen.
            Gegenstand der so erhobenen und pseudonymisiert verarbeiteten
            Informationen sind grunds&auml;tzlich die Ger&auml;te-ID, der
            Ger&auml;te-Typ, Zeitstempel, das verwendete Betriebssystem und die
            IP-Adresse. Die Informationen k&ouml;nnen der Person des Nutzers
            unter Zuhilfenahme weiterer Informationen, die die TikTok z.B.
            aufgrund der Inhaberschaft eines Accounts auf dem sozialen Netzwerk
            &bdquo;TikTok&ldquo; &uuml;ber den Nutzer gespeichert hat,
            zugeordnet werden. TikTok kann die &uuml;ber das Pixel erhobenen
            Informationen au&szlig;erdem mit weiteren Informationen, die TikTok
            &uuml;ber andere Webseiten und / oder im Zusammenhang mit der
            Nutzung des sozialen Netzwerks &bdquo;TikTok&ldquo; erhoben hat,
            verbinden, und so pseudonymisierte Nutzungsprofile erstellen. In
            keinem Fall k&ouml;nnen die erfassten Informationen dazu verwendet
            werden, Besucher dieser Website pers&ouml;nlich zu identifizieren.
          </p>
          <p>
            Das TikTok Pixel erm&ouml;glicht es uns weiterhin, die Wirksamkeit
            von Werbeanzeigen auf TikTok nachzuvollziehen. Wird der Nutzer von
            einer Anzeige auf TikTok auf Seiten dieser Website weitergeleitet
            und sind die Cookies noch nicht abgelaufen, erfasst das Pixel
            bestimmte von uns vordefinierte Nutzerhandlungen und kann diese
            nachvollziehen (z.B. abgeschlossene Transaktionen, Leads,
            Suchanfragen auf der Website, Aufrufe von Produktseiten). Bei
            Ausf&uuml;hrung einer solchen Aktion sendet Ihr Browser &uuml;ber
            den TikTok Pixel vom Cookie aus eine HTTP-Anfrage (Request) an den
            Server von TikTok, mit welcher bestimmte Informationen zur Aktion
            &uuml;bermittelt werden. Durch diese &Uuml;bermittlung kann TikTok
            Statistiken &uuml;ber das Nutzungsverhalten auf unserer Website nach
            Weiterleitung von einer TikTok-Anzeige erstellen, die uns zur
            Optimierung unseres Angebots dienen.
          </p>
          <p>
            Alle oben beschriebenen Verarbeitungen, insbesondere das Setzen von
            Cookies f&uuml;r das Auslesen von Informationen auf dem verwendeten
            Endger&auml;t, werden nur dann vollzogen, wenn Sie uns
            gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO dazu Ihre
            ausdr&uuml;ckliche Einwilligung erteilt haben. Ohne diese
            Einwilligungserteilung unterbleibt der Einsatz des TikTok Pixels
            w&auml;hrend Ihres Seitenbesuchs.
          </p>
          <p>
            Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung
            f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben,
            deaktivieren Sie diesen Dienst bitte in dem auf der Webseite
            bereitgestellten &bdquo;Cookie-Consent-Tool&ldquo;. Wir haben mit
            TikTok f&uuml;r die Nutzung des TikTok Pixel einen
            Auftragsverarbeitungsvertrag abgeschlossen, mit dem TikTok
            verpflichtet wird, die Daten unserer Seitenbesucher zu sch&uuml;tzen
            und sie nicht an Dritte weiter zu geben. TikTok &uuml;bermittelt
            erhobene Informationen grunds&auml;tzlich au&szlig;erhalb des
            Europ&auml;ischen Wirtschaftsraums und beruft sich hierbei auf sog.
            Standarddatenschutzklauseln der Europ&auml;ischen Kommission, welche
            die Einhaltung des europ&auml;ischen Datenschutzniveaus
            gew&auml;hrleisten sollen.
          </p>
          <p>
            Weitere Hinweise zu den Datenschutzbestimmungen von TikTok finden
            Sie hier:{" "}
            <a
              href="https://www.tiktok.com/legal/new-privacy-policy?lang=de-DE"
              target="_blank"
              rel="noopener"
            >
              https://www.tiktok.com/legal/new-privacy-policy?lang=de-DE
            </a>
          </p>
          <h2>10) Seitenfunktionalit&auml;ten</h2>
          <p>
            <strong>10.1</strong> Verwendung von Youtube-Videos
          </p>
          <p>
            Diese Website nutzt die Youtube-Einbettungsfunktion zur Anzeige und
            Wiedergabe von Videos des Anbieters &bdquo;Youtube&ldquo;, der zu
            der Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04
            E5W5, Irland (&bdquo;Google&ldquo;) geh&ouml;rt.
          </p>
          <p>
            Hierbei wird der erweiterte Datenschutzmodus verwendet, der nach
            Anbieterangaben eine Speicherung von Nutzerinformationen erst bei
            Wiedergabe des/der Videos in Gang setzt. Wird die Wiedergabe
            eingebetteter Youtube-Videos gestartet, setzt der Anbieter
            &bdquo;Youtube&ldquo; Cookies ein, um Informationen &uuml;ber das
            Nutzerverhalten zu sammeln. Hinweisen von &bdquo;Youtube&ldquo;
            zufolge dienen diese unter anderem dazu, Videostatistiken zu
            erfassen, die Nutzerfreundlichkeit zu verbessern und
            missbr&auml;uchliche Handlungsweisen zu unterbinden. Wenn Sie bei
            Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto
            zugeordnet, wenn Sie ein Video anklicken. Wenn Sie die Zuordnung mit
            Ihrem Profil bei YouTube nicht w&uuml;nschen, m&uuml;ssen Sie sich
            vor Aktivierung des Buttons ausloggen. Ihnen steht ein
            Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei
            Sie sich zur Aus&uuml;bung dessen an YouTube richten m&uuml;ssen. Im
            Rahmen der Nutzung von Youtube kann es auch zu einer
            &Uuml;bermittlung von personenbezogenen Daten an die Server der
            Google LLC. in den USA kommen.
          </p>
          <p>
            Unabh&auml;ngig von einer Wiedergabe der eingebetteten Videos wird
            bei jedem Aufruf dieser Website eine Verbindung zum Google-Netzwerk
            aufgenommen, was ohne unseren Einfluss weitere
            Datenverarbeitungsvorg&auml;nge ausl&ouml;sen kann.
          </p>
          <p>
            Alle oben beschriebenen Verarbeitungen, insbesondere das Auslesen
            von Informationen auf dem verwendeten Endger&auml;t &uuml;ber das
            Tracking-Pixel, werden nur dann vollzogen, wenn Sie uns
            gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO dazu Ihre
            ausdr&uuml;ckliche Einwilligung erteilt haben. Ohne diese
            Einwilligungserteilung unterbleibt der Einsatz von Youtube-Videos
            w&auml;hrend Ihres Seitenbesuchs.
          </p>
          <p>
            Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung
            f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben,
            deaktivieren Sie diesen Dienst bitte in dem auf der Webseite
            bereitgestellten &bdquo;Cookie-Consent-Tool&ldquo; &uuml;ber
            alternative, Ihnen auf der Website mitgeteilte M&ouml;glichkeiten.
          </p>
          <p>
            Weitere Informationen zum Datenschutz bei &bdquo;Youtube&ldquo;
            finden Sie in den Youtube-Nutzungsbedingungen unter{" "}
            <a
              href="https://www.youtube.com/static?template=terms"
              target="_blank"
              rel="noopener"
            >
              https://www.youtube.com/static?template=terms
            </a>{" "}
            sowie in der Datenschutzerkl&auml;rung von Google unter{" "}
            <a
              href="https://www.google.de/intl/de/policies/privacy"
              target="_blank"
              rel="noopener"
            >
              https://www.google.de/intl/de/policies/privacy
            </a>
          </p>
          <p>
            <strong>10.2</strong> - Google Web Fonts
          </p>
          <p>
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
            genannte Web Fonts die von der Google Ireland Limited, Gordon House,
            4 Barrow St, Dublin, D04 E5W5, Irland (&bdquo;Google&ldquo;)
            bereitgestellt werden. Beim Aufruf einer Seite l&auml;dt Ihr Browser
            die ben&ouml;tigten Web Fonts in ihren Browser-Cache, um Texte und
            Schriftarten korrekt anzuzeigen.
          </p>
          <p>
            Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
            den Servern von Google aufnehmen. Hierbei kann es auch zu einer
            &Uuml;bermittlung von personenbezogenen Daten an die Server der
            Google LLC. in den USA kommen. Auf diese Weise erlangt Google
            Kenntnis dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse unsere Website
            aufgerufen wurde. Die Verarbeitung von personenbezogenen Daten im
            Zuge der Verbindungsaufnahme mit dem Anbieter der Schriftarten wird
            nur dann vollzogen, wenn Sie uns gem&auml;&szlig; Art. 6 Abs. 1 lit.
            a DSGVO dazu Ihre ausdr&uuml;ckliche Einwilligung erteilt haben. Sie
            k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung
            f&uuml;r die Zukunft widerrufen, indem Sie diesen Dienst in dem auf
            der Webseite bereitgestellten &bdquo;Cookie-Consent-Tool&ldquo;
            deaktivieren. Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt,
            wird eine Standardschrift von Ihrem Computer genutzt.
          </p>
          <p>
            Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
            <a
              href="https://developers.google.com/fonts/faq"
              target="_blank"
              rel="noopener"
            >
              https://developers.google.com/fonts/faq
            </a>{" "}
            und in der Datenschutzerkl&auml;rung von Google:{" "}
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noopener"
            >
              https://www.google.com/policies/privacy/
            </a>
          </p>
          <h2>11) Tools und Sonstiges</h2>
          <p>Cookie-Consent-Tool</p>
          <p>
            Diese Website nutzt zur Einholung wirksamer Nutzereinwilligungen
            f&uuml;r einwilligungspflichtige Cookies und cookie-basierte
            Anwendungen ein sog. &bdquo;Cookie-Consent-Tool&ldquo;. Das
            &bdquo;Cookie-Consent-Tool&ldquo; wird Nutzern bei Seitenaufruf in
            Form einer interaktive Benutzeroberfl&auml;che angezeigt, auf
            welcher sich per H&auml;kchensetzung Einwilligungen f&uuml;r
            bestimmte Cookies und/oder cookie-basierte Anwendungen erteilen
            lassen. Hierbei werden durch den Einsatz des Tools alle
            einwilligungspflichtigen Cookies/Dienste nur dann geladen, wenn der
            jeweilige Nutzer entsprechende Einwilligungen per
            H&auml;kchensetzung erteilt. So wird sichergestellt, dass nur im
            Falle einer erteilten Einwilligung derartige Cookies auf dem
            jeweiligen Endger&auml;t des Nutzers gesetzt werden.
          </p>
          <p>
            Das Tool setzt technisch notwendige Cookies, um Ihre
            Cookie-Pr&auml;ferenzen zu speichern. Personenbezogene Nutzerdaten
            werden hierbei grunds&auml;tzlich nicht verarbeitet.
          </p>
          <p>
            Kommt es im Einzelfall zum Zwecke der Speicherung, Zuordnung oder
            Protokollierung von Cookie-Einstellungen doch zur Verarbeitung
            personenbezogener Daten (wie etwa der IP-Adresse), erfolgt diese
            gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres
            berechtigten Interesses an einem rechtskonformen, nutzerspezifischen
            und nutzerfreundlichen Einwilligungsmanagement f&uuml;r Cookies und
            mithin an einer rechtskonformen Ausgestaltung unseres
            Internetauftritts.
          </p>
          <p>
            Weitere Rechtsgrundlage f&uuml;r die Verarbeitung ist ferner Art. 6
            Abs. 1 lit. c DSGVO. Wir unterliegen als Verantwortliche der
            rechtlichen Verpflichtung, den Einsatz technisch nicht notwendiger
            Cookies von der jeweiligen Nutzereinwilligung abh&auml;ngig zu
            machen.
          </p>
          <p>
            Weitere Informationen zum Betreiber und den
            Einstellungsm&ouml;glichkeiten des Cookie-Consent-Tools finden Sie
            direkt in der entsprechenden Benutzeroberfl&auml;che auf unserer
            Website.
          </p>
          <h2>12) Rechte des Betroffenen</h2>
          <p>
            <strong>12.1</strong> Das geltende Datenschutzrecht gew&auml;hrt
            Ihnen gegen&uuml;ber dem Verantwortlichen hinsichtlich der
            Verarbeitung Ihrer personenbezogenen Daten die nachstehenden
            Betroffenenrechte (Auskunfts- und Interventionsrechte), wobei
            f&uuml;r die jeweiligen Aus&uuml;bungsvoraussetzungen auf die
            angef&uuml;hrte Rechtsgrundlage verwiesen wird:
          </p>
          <ul>
            <li>Auskunftsrecht gem&auml;&szlig; Art. 15 DSGVO;</li>
            <li>Recht auf Berichtigung gem&auml;&szlig; Art. 16 DSGVO;</li>
            <li>Recht auf L&ouml;schung gem&auml;&szlig; Art. 17 DSGVO;</li>
            <li>
              Recht auf Einschr&auml;nkung der Verarbeitung gem&auml;&szlig;
              Art. 18 DSGVO;
            </li>
            <li>Recht auf Unterrichtung gem&auml;&szlig; Art. 19 DSGVO;</li>
            <li>
              Recht auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20
              DSGVO;
            </li>
            <li>
              Recht auf Widerruf erteilter Einwilligungen gem&auml;&szlig; Art.
              7 Abs. 3 DSGVO;
            </li>
            <li>Recht auf Beschwerde gem&auml;&szlig; Art. 77 DSGVO.</li>
          </ul>
          <p>
            <strong>12.2</strong> WIDERSPRUCHSRECHT
          </p>
          <p>
            WENN WIR IM RAHMEN EINER INTERESSENABW&Auml;GUNG IHRE
            PERSONENBEZOGENEN DATEN AUFGRUND UNSERES &Uuml;BERWIEGENDEN
            BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE
            RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION
            ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG F&Uuml;R
            DIE ZUKUNFT EINZULEGEN.
          </p>
          <p>
            MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
            VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT
            ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE
            F&Uuml;R DIE VERARBEITUNG NACHWEISEN K&Ouml;NNEN, DIE IHRE
            INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN &Uuml;BERWIEGEN, ODER
            WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER
            VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN DIENT.
          </p>
          <p>
            WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM
            DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT
            WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
            PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN.
            SIE K&Ouml;NNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUS&Uuml;BEN.
          </p>
          <p>
            MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
            VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.
          </p>
          <h2>13) Dauer der Speicherung personenbezogener Daten</h2>
          <p>
            Die Dauer der Speicherung von personenbezogenen Daten bemisst sich
            anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und
            &ndash; sofern einschl&auml;gig &ndash; zus&auml;tzlich anhand der
            jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und
            steuerrechtliche Aufbewahrungsfristen).
          </p>
          <p>
            Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer
            ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit.
            a DSGVO werden diese Daten so lange gespeichert, bis der Betroffene
            seine Einwilligung widerruft.
          </p>
          <p>
            Existieren gesetzliche Aufbewahrungsfristen f&uuml;r Daten, die im
            Rahmen rechtsgesch&auml;ftlicher bzw.
            rechtsgesch&auml;fts&auml;hnlicher Verpflichtungen auf der Grundlage
            von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese
            Daten nach Ablauf der Aufbewahrungsfristen routinem&auml;&szlig;ig
            gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder
            Vertragsanbahnung erforderlich sind und/oder unsererseits kein
            berechtigtes Interesse an der Weiterspeicherung fortbesteht.
          </p>
          <p>
            Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert,
            bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO
            aus&uuml;bt, es sei denn, wir k&ouml;nnen zwingende
            schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen,
            die die Interessen, Rechte und Freiheiten der betroffenen Person
            &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung,
            Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.
          </p>
          <p>
            Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der
            Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden
            diese Daten so lange gespeichert, bis der Betroffene sein
            Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO aus&uuml;bt.
          </p>
          <p>
            Sofern sich aus den sonstigen Informationen dieser Erkl&auml;rung
            &uuml;ber spezifische Verarbeitungssituationen nichts anderes
            ergibt, werden gespeicherte personenbezogene Daten im &Uuml;brigen
            dann gel&ouml;scht, wenn sie f&uuml;r die Zwecke, f&uuml;r die sie
            erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr
            notwendig sind.
          </p>
          <h2>14) Erg&auml;nzung zur Datenverarbeitung</h2>
          <p>
            Wir geben Ihre erhobenen Personendaten nur dann an Dritte weiter,
            wenn ein Fall des Art. 6 Abs. 1 lit. b DSGVO vorliegt. Ansonsten
            geben wir diese nur in anonymisierter Form an Dritte weiter. Dies
            gilt nicht f&uuml;r produktbezogene Daten, die keinen direkten
            Zusammenhang zu Ihrer Person haben. Das ist insbesondere die
            FIN-Nummer Ihres Fahrzeuges. Mit dem Abschluss dieses Vertrages sind
            Sie mit der Weitergabe der FIN-Nummer Ihres Fahrzeuges an alle
            f&uuml;r die Erhebung von Verkehrsdaten und die Auszahlung von
            THG-Quoten zust&auml;ndigen Bundesministerien sowie privat
            gef&uuml;hrte Autoh&auml;user, die Ihnen pers&ouml;nlich als Nutzer
            unsere Produkte empfohlen haben und Sie sich aufgrund dieser
            Empfehlung f&uuml;r unsere Leistungen entschieden haben,
            einverstanden. Die Weitergabe dieser Daten erfolgt in elektronischer
            Form oder postalisch.&nbsp;
          </p>
          <h4>
            Nach &sect; 34 BDSG haben Sie einen Anspruch auf Auskunft &uuml;ber
            den Umfang Ihrer gespeicherten Daten. Sie sind jederzeit berechtigt,
            Auskunft von uns zu verlangen &uuml;ber die Adressaten Ihrer Daten.
            Gem&auml;&szlig; &sect; 35 BDSG k&ouml;nnen Sie jederzeit die
            Berichtigung, L&ouml;schung und Sperrung einzelner personenbezogener
            Daten verlangen. Sie k&ouml;nnen dar&uuml;ber hinaus jederzeit ohne
            Angabe von Gr&uuml;nden von Ihrem Widerspruchsrecht Gebrauch machen
            und die erteilte Einwilligungserkl&auml;rung mit Wirkung f&uuml;r
            die Zukunft ab&auml;ndern oder g&auml;nzlich widerrufen. Sie
            k&ouml;nnen den Widerruf entweder postalisch, per E-Mail oder per
            Fax an den Vertragspartner &uuml;bermitteln. Es entstehen Ihnen
            dabei keine anderen Kosten als die Portokosten bzw. die
            &Uuml;bermittlungskosten nach den bestehenden Basistarifen.
          </h4>
        </section>
      </BasicPage>
    </>
  )
}

export default Datenschutz
